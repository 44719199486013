import { Controller } from "@hotwired/stimulus";
import { formatDuration } from "../src/formatDuration";

export default class BillCountdownController extends Controller {

  connect() {
    this.timer = null;

    const dataset = this.element.dataset;
    this.billId = dataset.billId
    this.duration = undefined;
    this.text = dataset.text;
    this.redirectUrl = dataset.redirectUrl;
    this.disabled = false;

    const _self = this;

    this.subscription = App.cable.subscriptions.create({ channel: "BillTimerChannel", bill_id: this.billId }, {
      connected: () => {
        _self.start();
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
      },

      disconnected: () => {
        _self.stop();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      },

      received: (data) => {
        if (data.command === 'update_timer') {
          if (!document.body.dataset.paymentProcessing && data.removed === true) {
            window.location.href = this.redirectUrl || '/';
          } else {
            const duration = parseInt(data.order_left_duration_seconds);
            _self.duration = Math.max(duration, 0)
            _self.start();
          }
        }

        if (data.command === 'redirect') {
          window.location.href = this.redirectUrl || '/';
        }
      }
    });
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.subscription.send({ command: 'get_timer' });
    }
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  start() {
    this.stop();
    this.updateText();
    if (this.duration <= 0) this.disableBill();

    const onTimerUpdate = () => {
      this.updateText();
      this.disableBill();
    }

    this.timer = setInterval(() => {
      this.duration--;

      if (this.duration <= 0) {
        onTimerUpdate();
      } else {
        this.updateText();
      }
    }, 1000);
  }

  disableBill() {
    if (this.disabled) return;

    function createOverlay() {
      const disabledDiv = document.createElement('div');
      disabledDiv.classList.add('disabled_window');
      return disabledDiv;
    }

    function addOverlayIfNotExist(targetElement) {
      // Check if an overlay already exists in the target element
      if (targetElement && !targetElement.querySelector('.disabled_window')) {
        targetElement.insertBefore(createOverlay(), targetElement.firstChild);
      }
    }

    const amendmentBlock = document.getElementById('bookings-block');
    addOverlayIfNotExist(amendmentBlock);

    const addSessionsBlock = document.getElementById('add_sessions_block');
    if (addSessionsBlock && addSessionsBlock.hasChildNodes()) {
      const parent2Block = document.getElementById('add-sessions-block');
      addOverlayIfNotExist(parent2Block);
    }

    const checkoutBox = document.getElementById('checkout-box');
    addOverlayIfNotExist(checkoutBox);

    this.disabled = true;
  }


  updateText() {
    this.element.innerHTML = this.prepareText();
    this.element.classList.remove('invisible');
  }

  prepareText() {
    let text = this.text;
    if (!text) text = 'You have #time to complete this stage of the booking process'

    const duration = this.duration < 0 ? 0 : this.duration;

    return text.replace('#time', formatDuration(duration * 1000));
  }

  stop() {
    if (this.timer) clearInterval(this.timer);
  }
}

